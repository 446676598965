import React from "react"
import styled from "styled-components"

const Headline = styled.h2`
  font-size: 4rem;
  font-weight: 900;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  letter-spacing: -1.66px;
`
const Offer = styled.p`
  font-family: 'Roboto Mono', monospace;
  list-style-type: none;
  font-size: 14px;
  line-height: 35px;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1pt;
  font-weight: 500;
  color: #202020;
`

const Offerings = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-sm-12 p-lg-0">
          <Headline className="">Offerings</Headline>
        </div>
        <div className="col-lg-3 col-sm-12">
          <Offer>Lexicon Development</Offer>
          <Offer>Scientific platform</Offer>
          <Offer>KOL videos</Offer>
          <Offer>Monographs</Offer>
          <Offer>KOL and Influence mapping</Offer>
          <Offer>Disease education</Offer>
          <Offer>Real-world evidence</Offer>
          <Offer>Pipeline communications</Offer>
        </div>
        <div className="col-lg-4 col-sm-12">
          <Offer>Data visualization</Offer>
          <Offer>Clinical trial recruitment</Offer>
          <Offer>MSL materials</Offer>
          <Offer>MOA/MOD models and videos</Offer>
          <Offer>Omnichannel engagement</Offer>
          <Offer>Peer-to-peer decks</Offer>
          <Offer>Virtual conventions</Offer>
          <Offer>Promotional video abstracts</Offer>
        </div>
      </div>
    </div>
  )
}

export default Offerings
