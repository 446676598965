import React from "react"
import styled from "styled-components"
import ImgButton from "../ImgButton/ImgButton"

const Headline = styled.h2`
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  letter-spacing: -1.66px;
`
const Description = styled.p`
  font-size: 1.7rem;
  font-family: Work Sans, sans-serif;
  line-height: 150%;
  margin: 0 0 2.5rem 0;
  padding-right: 30px;
  max-width: 485px;
  text-align: left;
`

const meetTheBloc = () => {
  return (
    <div className="container section-padding">
      <div className="row">
        <div className="col-lg-6 pl-lg-0 mb-40">
          <Headline>Allied. Agile. Able.</Headline>
          <Description>
            The BlocPartners is the strongest network of interdependent
            health-native creative and medical communication agencies in the
            world. We're bound together by a passion for solving the biggest
            problems in health and breaking barriers across continents.
          </Description>
          <ImgButton
            btnLabel="Meet The blocpartners"
            link="https://www.theblocpartners.com/"
            imgColor="green smart-text-align"
            fillColor="#c2d500"
          />
        </div>
        <div className="col-lg-6 mb-40">
          <Headline>Be Great to do Good</Headline>
          <Description className="" style={{ maxWidth: "420px !important" }}>
            The Bloc is the most diversified health creative agency in the
            world. Our dynamic team of thinkers, doers, designers, developers,
            and MDs and PhDs, is driven by one core goal:
            <br /> Be Great to Do Good.
          </Description>
          <ImgButton
            btnLabel="Meet The bloc"
            link="https://thebloc.com/"
            imgColor="orange  smart-text-align d-w-220"
            fillColor="#ff8c00"
          />
        </div>
      </div>
    </div>
  )
}

export default meetTheBloc
