import React from "react"
import styled from "styled-components"

import ImgCindy from "../images/cindySVG"
import HeroHeadline from "../images/heroHeadline"

import "./hero.scss"

const HeroContainer = styled.div`
  padding-top: 8rem;
  padding-bottom: 1rem;
  background: rgb(202,118,196);
    background: linear-gradient(150deg, rgba(202,118,196,.9) 0%, 
      rgba(127,65,212,.9) 10%, 
      rgba(37,198,223,1) 35%,
      rgba(108,229,248, .21) 53%, 
      #ffffff 65%, 
      #fffffd 70%, 
      #FCFFA0 100%);
  @media only screen and (min-width: 768px) {
    background: rgb(202,118,196);
    background: linear-gradient(125deg, rgba(202, 118, 196, 0.9) -3%, rgba(127, 65, 212, 0.9) 7%, rgba(37, 198, 223, 0.83) 29%, rgba(108, 229, 248, 0.21) 60%, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 253, 0.03) 72%, #ffe351);

  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    overflow: hidden;
  }
  .hollow {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: transparent;
  }
`

// const PreHeader = styled.p`
//   font-family: "Roboto", sans-serif;
//   text-transform: uppercase;
//   margin-bottom: 1rem;
//   font-size: 1.5rem;
//   @media only screen and (min-width: 768px) {
//     padding-top: 5.5rem;
//   }
// `

const Headline = styled.h1`
  font-size: 4.4rem;
  line-height: 3.6rem;
  letter-spacing: -3px;
  margin-top: 1rem;
  margin-bottom: 2.7rem;
  @media only screen and (min-width: 768px) {
    margin-top: 0;
    padding-top: 6.5rem;
    font-size: 5.9rem;
    line-height: 4.5rem;
  }
`
const BlocPara = styled.p`
  font-family: "Work Sans", sans-serif;
  color: #202020;
  font-size: 1.7rem;
  line-height: 2.3rem;
  @media only screen and (min-width: 768px) {
    max-width: 520px;
    margin-bottom: 9rem;
  }
`

const Hero = () => {
  return (
    <HeroContainer>
      <div className="bg-cindy-img">
        <div className="container">
          <div className="row">
            
            <div className="col-md-7 col-xl-5">
              <Headline>
                Science isn't
                <br /> just what <span className="text-nowrap">we do</span>,
                <br />{" "}
                <HeroHeadline />
              </Headline>
              <BlocPara>
                The Bloc Science Foundry is the medical communications practice
                at The Bloc. It’s where scientific narratives are forged to give
                your brand the strength it needs to succeed. We relentlessly
                apply principles of the science of communication to your data,
                so that the truth of your message is immediately evident and
                drives behavior change.
              </BlocPara>
            </div>
            <div className="col-md-5 col-xl-7 align-self-md-center">
              <ImgCindy />
            </div>
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}

export default Hero
