import React from "react"

const ImgButton = ({ btnLabel, link, imgColor, fillColor }) => (
  <a
    href={link}
    className={`btn-svg-nav bloc-btn svg-btn ${imgColor}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg style={{ fill: fillColor }} viewBox="0 0 626.5 550.1">
      <path
        d="M2.5,2.5H520.68V68.65L454.53,43.84S440.75,38.33,427,52.11,352.55,132,352.55,132s-5.51,5.51-5.51,11-30.32,317-30.32,317H2.5Z"
        transform="translate(-2.5 -2.5)"
      />
      <path
        d="M446.26,140.31V76.92s2.76-11,19.29-5.51S570.29,110,570.29,110s13.78,5.51,13.78,16.54l5.51,60.64s2.76,13.78-11,11-82.69-27.56-82.69-27.56,2.76-8.27-8.27-13.78S446.26,140.31,446.26,140.31Z"
        transform="translate(-2.5 -2.5)"
      />
      <path
        d="M504.14,289.15s5.51-22.05-24.81-27.56l-33.08-8.27V203.71s0-19.29,22.05-13.78,102,30.32,102,30.32,22.05,5.51,24.81,22.05,5.51,52.37,5.51,52.37,2.76,19.29-16.54,13.78Z"
        transform="translate(-2.5 -2.5)"
      />
      <path
        d="M446.26,388.38V325s2.76-19.29,27.56-13.78S589.59,336,589.59,336s16.54,8.27,19.29,24.81,8.27,88.2,8.27,88.2,5.51,16.54-16.54,13.78-82.69-8.27-82.69-8.27l-2.76-33.08s-2.76-22.05-16.54-24.81S446.26,388.38,446.26,388.38Z"
        transform="translate(-2.5 -2.5)"
      />
      <path
        d="M523.43,531.7l-2.76-11s0-11-19.29-13.78l-44.1-2.76s-2.76-19.29,16.54-19.29,121.28,11,121.28,11,19.29,2.76,30.32,35.83Z"
        transform="translate(-2.5 -2.5)"
      />
    </svg>
    {btnLabel.toUpperCase()}
  </a>
)

export default ImgButton
