import React from "react"
import styled from "styled-components"

import HeadlineDesktop from "../../images/cindy/IT’S_HOW_WE_DO_IT_desktop.svg"
import HeadlineMobile from "../../images/cindy/IT’S_HOW_WE_DO_IT_mobile.svg"

const ImgMobile = styled.img`
  margin-top: 5px;
  width: 278px;
  @media(min-width: 768px) {
    width: 388px;
  }
`
const ImgDesktop = styled.img`
  width: 49rem;
`

const HeroHeadline = () => {
  return (
    <>
      <div className="d-block d-lg-none">
        <ImgMobile src={HeadlineMobile} alt="IT’S HOW WE DO IT" />
      </div>
      <div className="d-none d-lg-block">
        <ImgDesktop src={HeadlineDesktop} alt="IT’S HOW WE DO IT" />
      </div>
    </>
  )
}

export default HeroHeadline
