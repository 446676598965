import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import CaseStudy from "../components/caseStudy/CaseStudy"
import EmailWhitePaper from "../components/emailWhitePaper/EmailWhitePaper"
import Offerings from "../components/offerings/offerings"
import Leadership from "../components/leadership/leadership"

import MeetTheBloc from "../components/meetTheBloc/meetTheBloc"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[
          `Scientific Narrative, Lexicon, Medical Communications, peer-to-peer, Investor relations, pipeline, KOL mapping,`,
        ]}
        description="Science isn’t just what we do, it’s how we do it."
      />
      <Hero />
      <CaseStudy />
      <EmailWhitePaper />
      <Offerings />
      <Leadership />
      <MeetTheBloc />
    </Layout>
  )
}

export default IndexPage
