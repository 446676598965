import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import barbara from "../../images/leadership_large/barbara.png"
import kim from "../../images/leadership_large/kim.png"
import ane from "../../images/leadership_large/ane.png"
import allison from "../../images/leadership_large/allison.png"

const Headline = styled.h2`
  font-size: 4rem;
  font-weight: 900;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  text-align: center;
  letter-spacing: -1.66px;
`
const Name = styled.h3`
  font-size: 24px;
  letter-spacing: -1px;
  font-weight: 900;
  line-height: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 1.5rem;
  text-align: left;
  max-width: 150px;
  color: #202020;
`
const Title = styled.p`
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  letter-spacing: 3px;
  position: relative;
  top: 5px;
  width: 100%;
  text-transform: uppercase;
  font-style: italic;
  line-height: 14px;
  text-align: left;
  color: #202020;
`

const Leadership = () => {
  return (
    <div className="container section-padding">
      <div className="row bg-bloc-gray pt-40">
        <div className="col-12">
          <Headline>Leadership</Headline>
        </div>
        <div className="container mt-20">
          <div className="row" style={{justifyContent:"center"}}>
            <div
              className="col-lg-4 flex-center col-sm-12 desk-px-35 mobile-mb-40"
              role="figure"
            >
              <Link to="/barbara_lepetri">
                <div className="image-hover-container">
                  <img src={barbara} alt="Barbara LePetri, MD" className="w-100" />
                </div>
                <div className="ml-0 mw-190">
                  <Name>Barbara LePetri, MD</Name>
                  <Title>Medical and Scientific Leadership</Title>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Leadership
