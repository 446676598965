import styled from 'styled-components';

const EWPContainer = styled.div`
  background: rgb(202,118,196);
  background: linear-gradient(-160deg, rgba(202,118,196,.9) 0%, 
    rgba(127,65,212,.9) 10%, 
    rgba(37,198,223,1) 35%,
    rgba(108,229,248, .21) 53%, 
    #ffffff 65%, 
    #fffffd 80%, 
    #FCFFA0 100%);
  padding: 5.5rem 2rem;
  margin-bottom: 5.5rem;
  @media only screen and (min-width: 768px) {
    background: rgb(202,118,196);
    background: linear-gradient(-125deg, rgba(202,118,196,.9) 0%, 
      rgba(127,65,212,.9) 10%, 
      rgba(37,198,223,1) 35%,
      rgba(108,229,248, .21) 53%, 
      #ffffff 65%, 
      #fffffd 70%, 
      #FCFFA0 100%);
  }
`

const Headline = styled.h2`
  font-size: 4rem;
  font-weight: 900;
  line-height: 3.2rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2.5rem;
`
const Description = styled.p`
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-align: center;
  width: auto;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  @media only screen and (min-width: 768px) {
    width: 80%;
    margin: 0 auto 2.5rem;
  }
`

const InputLabel = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.7rem;
  }
`

const FormContainer = styled.div`
  width: 100%;
  margin: auto;
  @media only screen and (min-width: 768px) {
    width: 70%;
  }
  input {
    border-radius: 0;
    border: solid 1px #202020;
    font-size: 1.4rem;
    padding: 1.6rem;
    height: 5.5rem;
  }
  button {
    border-radius: 0;
    background-color: #202020;
    color: #ffffff;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1.6rem 5rem;
  }
`

const SucessSentMsg = styled.p`

    font-weight: 300;
    font-style: italic;
    margin-top:2rem;
    display:flex;
    align-items: center;
    img {
      margin-right:1rem;
    }
    span {
      line-height:120%;
    }
    
`

export {EWPContainer, Headline, Description, InputLabel, FormContainer, SucessSentMsg}