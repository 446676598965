import React, { useState } from "react"
import axios from "axios"
import {useFormik} from 'formik'
import * as Yup from 'yup'
import "./whitepaper.scss"


import {EWPContainer, Headline, Description, InputLabel, FormContainer, SucessSentMsg} from "./EmailWhitePaperStyle"

import checkIcon from "../../images/check-icon.png"



const EmailWhitePaper = () => {
  const [successSent, setSuccessSet] = useState(false)

  const {handleSubmit, handleChange, values, touched, errors, handleBlur} = useFormik({
    initialValues: {
        email: ''
    },
    validationSchema: Yup.object({
        email: Yup.string().email().required('Email is a required field.')
    }),
    onSubmit: ({email}) => {

      const btnSubmit = document.getElementById("submitWhitePaper");
      const inputEmail = document.getElementById("email");

      btnSubmit.disabled = true;
      inputEmail.disabled = true;
      const LOCAL_PATH = "http://localhost:8888/static/mailer/"
      const PATH = "/mailer/";
      const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV;
      let API_PATH = PATH;
      if(activeEnv === 'development'){
        API_PATH = LOCAL_PATH
      }
      const qs = require('qs'); 
      axios.post(API_PATH, 
        qs.stringify({"email": email}, { parseArrays: false })
      ).then(res => {
        setSuccessSet(true);
        btnSubmit.disabled = true;
        inputEmail.disabled = true;
        inputEmail.value = '';
      })
      .catch(error => {
        btnSubmit.disabled = false;
        inputEmail.disabled = false;
        inputEmail.value = '';
        console.log({ error: error.message })
      })
    }
});

  // const handleChange = e => {
  //   if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
  //     document.querySelector("#email").style.borderColor = "#202020"
  //   } else {
  //     document.querySelector("#email").style.borderColor = "red"
  //   }
  //   setEmail(e.target.value)
  // }


  // const handleSubmit = e => {
  //   const LOCAL_PATH = "http://localhost:8888/static/mailer/"
  //   const PATH = "/mailer/";
  //   const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV;
  //   let API_PATH = PATH;
  //   if(activeEnv === 'development'){
  //     API_PATH = LOCAL_PATH
  //   }
  //   const qs = require('qs'); 
  //   axios.post(API_PATH, 
  //     qs.stringify({"email": email}, { parseArrays: false })
  //   ).then(res => {
  //     setSuccessSet(true)
  //   })
  //   .catch(error => console.log({ error: error.message }))
  //   if (!email) {
  //     console.log("empty")
  //   }
  // }

  return (
    <EWPContainer className="container section-padding">
      <div className="row ">
        <div className=" col">
          <Headline>
            Our approach to scientific strategy is destination-oriented
          </Headline>
          <Description>
            Contact us to receive our latest white paper describing our
            approach, including how evidence-based creative optimizes medical
            communications.
          </Description>
          <FormContainer>
            <form onSubmit={handleSubmit}>
              <InputLabel>
                Enter your email below for a link to the white paper
              </InputLabel>
              <div className="input-group mb-3">
                <input
                  type="email"
                  id="email"
                  placeholder="Email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  aria-label="Email address"
                  aria-describedby="button-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="submit"
                    id="submitWhitePaper"
                  >
                    Submit
                  </button>
                </div>
                
              </div>
              {touched.email && errors.email ? (
                    <div className="error-msg">{errors.email}</div>
                ): null}
            </form>
            <p className="hang-mobile-10">{successSent && (<SucessSentMsg ><img src={checkIcon} alt="Check Icon"/><span>Thank you. Please check your inbox for an email containing a link.</span></SucessSentMsg>)}</p>
            
          </FormContainer>
        </div>
      </div>
    </EWPContainer>
  )
}

export default EmailWhitePaper
