import React from "react"
import styled from "styled-components"

import CindyDesktop from "../../images/cindy/hero_wide.svg"
import CindyMobile from "../../images/cindy/hero_mobile.svg"

const ImgDesktop = styled.img`
  @media(max-width: 992px) {
    width: 220%;
    position: relative;
    left: -3rem;
    bottom: 3rem;
  }
  @media(min-width: 993px) and (max-width: 1200px){
    //  width:170%;
   }
`

const ImgCindy = () => {
  return (
    <>
      <div className="d-block d-md-none">
        <img src={CindyMobile} alt="Cindy" />
      </div>
      <div className="d-none d-md-block">
        <ImgDesktop src={CindyDesktop} alt="Cindy" />
      </div>
    </>
  )
}

export default ImgCindy
