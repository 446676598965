import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import classNames from "classnames"

import Art1 from "../../images/case_study/dArtboard1.png"
import Art2 from "../../images/case_study/dArtboard2.png"

const CaseImage = styled.img`
  width: 100%;
`
const CSContainer = styled.div`
  margin-bottom: 5rem;
`

const CSContent = styled.div`
  padding-top: 3.5rem;
`
const CaseStudyContainer = styled.div`
  position: relative;
`
const BGArt = styled.img`
  position: absolute;
  top: 12.5rem;
  right: 2rem;
  z-index: 99;
  width: 60%;

  @media only screen and (min-width: 768px) {
    right: auto;
    left: 2rem;
    width: 30%;
    top: 17rem;
  }
  @media only screen and (min-width: 992px) {
    width: 30%;
    top: 23rem;
  }
  @media only screen and (min-width: 1200px) {
    width: 30%;
    top: 25rem;
  }
`
const BGArt2 = styled.img`
  position: absolute;
  top: 40%;
  right: 2rem;
  z-index: 99;
  width: 60%;

  @media only screen and (min-width: 768px) {
    right: 2rem;
    width: 30%;
    top: 47rem;
  }

  @media only screen and (min-width: 992px) {
    right: 2rem;
    width: 30%;
    top: 56rem;
  }
  @media only screen and (min-width: 1200px) {
    width: 30%;
    top: 65rem;
  }
`

const Number = styled.p`
  color: #7f5dd9;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  @media only screen and (min-width: 768px) {
    margin-bottom: 2.5rem;
  }
`
const Description = styled.p`
  font-family: "Work Sans", sans-serif;
  color: #202020;
  line-height: 150%;
  max-width: 320px;
  font-size: 1.7rem;
  line-height: 2.3rem;
  @media only screen and (min-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.3rem;
    max-width: 340px;
  }
`

const Title = styled.h2`
  font-weight: 900;
  line-height: 4rem;
  text-transform: uppercase;
  font-size: 3.6rem;
  line-height: 2.8rem;
  margin-bottom: 1rem;
  @media only screen and (min-width: 768px) {
    margin-bottom: 2rem;
    line-height: 2.8rem;
  }
`

const CaseStudy = () => {
  const study = useStaticQuery(graphql`
    {
      allCaseStudyJson {
        edges {
          node {
            id
            number
            description
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <CaseStudyContainer className="container section-padding">
      <BGArt src={Art1} alt="" />
      <BGArt2 src={Art2} alt="" />
      {study.allCaseStudyJson.edges.map((item, index) => {
        return (
          <CSContainer key={item.node.id} className="row">
            <div
              className={classNames("col-md-7", {
                "order-md-12": index % 2,
              })}
            >
              <CaseImage src={item.node.image.childImageSharp.fluid.src} alt="" />
            </div>
            <CSContent className="col-md-5 order-md-1">
              <Number>{item.node.number}</Number>
              <Title>{item.node.title}</Title>
              <Description className="m-0">{item.node.description}</Description>
            </CSContent>
          </CSContainer>
        )
      })}
    </CaseStudyContainer>
  )
}

export default CaseStudy
